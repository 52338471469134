import $api from "../http";
import {AxiosResponse} from 'axios';
import {MyCfaInfoResponse} from '../models/response/MyCfaResponse';

export default class MyCfaInfoService {
    static async saveMyCfaInfoService(
        email: string,
        user: string,
        numberAccountFrom: string,
        freeAmount: string,
        sendAmount: string,
        dateAdded: string,
        totalAmount: string,
        emitID: string,
        cfaID: string,
        commisionPrice: string,
        ): Promise<AxiosResponse<MyCfaInfoResponse>> {
        return $api.post<MyCfaInfoResponse>('/saveMyCfa',{email, user, numberAccountFrom, freeAmount, sendAmount, dateAdded, totalAmount, emitID, cfaID, commisionPrice})
    }

    static async fetchAllMyCFA (email: string): Promise<AxiosResponse<MyCfaInfoResponse>> {
        return $api.post<MyCfaInfoResponse>('/getAllMyCfa',{email})
    }

    static async redeemCFA (emitID:string, cfaID:string): Promise<AxiosResponse<MyCfaInfoResponse>> {
        return $api.post<MyCfaInfoResponse>('/redeemCFA',{emitID, cfaID})
    }

    
}