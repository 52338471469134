import {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";

import {Context} from "../../../index";
import {Alert, Card, CardBody, CardTitle, Col, Form, Row, FormLabel, Button} from 'react-bootstrap';

import Modal from 'react-bootstrap/Modal';
import emitImage from "../../../img/icons/emiter-default.svg"
import ReleaseCfaService from '../../../services/ReleaseCfaService';

const RedeemCFA = (props) => {
    const {store} = useContext(Context);
    let emitID = props.emitID
    let cfaID = props.cfaID;
    const customerAmount = 10000000;
    
    const getReleaseCfaIyem = async () =>{
        const response = await ReleaseCfaService.getDetailCFA(emitID, cfaID);
        return response;
    }
    const {data, isSuccess, isError} = useQuery({
        queryKey: ['releaseCfa'],
        queryFn: getReleaseCfaIyem,
        select: data => data.data,
        enabled: store.isAuth && store.user.isActivated
    });
    if (isSuccess) {
        console.log('success')
    }
    if(isError) {
        store.errorMessage = 'Ошибка связи с Базой Данных, пожалуйста, обратитесь к администратору. Спасибо'
    }
    const cfaItem = data;
    const platformTax = 0.01;

    const [validatedBuyCFA, set_validatedBuyCFA] = useState(false);
    const [confirmationModal, set_confirmationModal] = useState(false);
    const [alertclass, set_alertclass] = useState('d-none');
    let countCfa = 0;
    if (cfaItem) {
        countCfa = parseInt(cfaItem.countCfa || 0);
    }

    const priceForRedeem = parseInt(cfaItem ? cfaItem.countCfaOrigin : 0) * parseInt(cfaItem ? cfaItem.priceCompleateCfa : 0);
    const taxPrice = priceForRedeem * platformTax;
    const totalPrice = priceForRedeem + taxPrice;

    const submitBuyCfa = (e) => {
        e.preventDefault();
        if (totalPrice > customerAmount) {
            set_alertclass('d-block')
        } else {
            store.redeemCFA(emitID,cfaID);
            set_confirmationModal(true);
        }
    }

    return (<>
      {store.isAuth && store.user.isActivated ? (<>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                  {confirmationModal ? (<>
                    <Modal.Body>
                    <p className="congrats-small">Поздравляем c погашением!</p>
                    <h4 className="congrats-header">Заявка на погашение ЦФА отправлена и будет скоро обработана</h4>
                    <p className="congrats-text">При изменении статуса придет уведомление, а также все можно отслеживать в личном кабинете</p>
                    </Modal.Body>
                    <Modal.Footer className="d-block text-center">
                        <a href="/release-cfa" className='btn btn-primary my-3'>Перейти к выпускам ЦФА</a>
                    </Modal.Footer>
                  </>) :(<>
                    <Row className="buy-cfa-modal align-items-stretch cfa-table-body me-0 ms-0">
                        <Col className="ps-0" lg={5}>
                            <div className='cfa-table-title h-100'>
                                <div className='d-flex align-items-center h-100'>
                                    <div>
                                      <div className='cfa-table-sub-title'>Digital-Grain_{cfaItem ? cfaItem.ticker : ''}</div>
                                      <div className='cfa-table-name'>{cfaItem ? cfaItem.releaseName : ''}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className='align-items-center'>
                          <div className='d-flex align-items-center h-100'>
                              <div>
                                  <img className="me-3" src={emitImage} alt="Эмитент"/><span>{cfaItem ? cfaItem.emitName : ''}</span>
                              </div>
                          </div>
                        </Col>
                        <Col className="text-center pe-5" lg={3}>
                            <div className='cfa-table-title gray-light h-100 p-1 text-start ps-4 d-flex align-items-center'>
                                <div>
                                    <div className='cfa-table-sub-title'>Цена погашения</div>
                                    <div className='cfa-table-name'>{cfaItem && cfaItem.priceCompleateCfa ? cfaItem.priceCompleateCfa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0} &#8381;</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <CardTitle className='mt-5'>Заявка на погашение ЦФА</CardTitle>
                    <Form noValidate validated={validatedBuyCFA} onSubmit={submitBuyCfa}>
                        <Card className="mb-3">
                            <CardBody>
                                <Row>
                                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationbnumberAccountFrom">
                                        <FormLabel className='fixed-label'>Счет списания</FormLabel>
                                        <Form.Control
                                            type="number"
                                            name="numberAccountFrom"
                                            value="4111111111111111"
                                            className='w-100 legal-form-1'
                                            disabled
                                            placeholder='Номер лицевого счета'
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationfreeAmount">
                                        <FormLabel className='fixed-label'>Свободных средств, ₽</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="freeAmount"
                                            value={customerAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                                            className='w-100 legal-form-1'
                                            disabled
                                            placeholder='Сумма свободных д/с на счёте'
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Form.Group lg={12} as={Col} className="mb-3 sendAmount" controlId="validationfcountCfa">
                                    <Form.Control
                                        type="number"
                                        max={countCfa}
                                        min={1}
                                        name="countCfa"
                                        value={cfaItem ? cfaItem.countCfaOrigin : ''}
                                        className='w-100 legal-form-1'
                                        required
                                        readOnly
                                        placeholder='Введите количество приобретаемых ЦФА'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Введите корректные данные
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <p className="text-green-dark mb-0">Стоимость погашаемых активов: <span className='amountTax'>{priceForRedeem.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} руб.</span></p>
                                <p className="text-green-dark mb-0">Комиссия за приобретение ЦФА {platformTax} %:  {taxPrice} руб.</p>
                                </CardBody>
                            </Card>
                        <Form.Group lg={12} as={Col} className="mb-3 totalAmount" controlId="validationftotalAmount">
                            <FormLabel className='fw-normal'>Итого</FormLabel>
                            <Form.Control
                                type="number"
                                name="totalAmount"
                                value={totalPrice}
                                className='w-100 legal-form-1'
                                required
                                disabled
                                placeholder='XXXXXXX'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        {store.errorMessage !== '' ? <Alert variant="danger">{store.errorMessage}</Alert> : ''}
                        <Alert className={alertclass} variant="danger">Недостаточно cвободных средств на балансе</Alert>
                        <div className='d-flex align-items-center mt-3 button-set'>
                            <Button className="me-3 text-nowrap" variant="primary" id="form-submit" type="submit">Погасить ЦФА</Button>
                            <p className='text-small m-0'>Вы собираетесь погасить выпуск ЦФА.
                                После подтверждения погашения с вашего счета будет списана сумма денежных средств, необходимая для погашения выпуска ЦФА.
                                инициирую процедуру погашения выпуска ЦФА, вы соглашаетесь с условиями Платформы.</p>
                        </div>
                    </Form>
                  </>)}
              </Modal.Body>
      </>) : (<></>)}
      </>);
}
 
export default observer(RedeemCFA);