import { FC, useContext, useRef, useState } from "react";
import {Button, Col, Container, Row, Form, Tab, Tabs, FormLabel, Alert, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from 'date-fns/locale/ru';

import {Context} from "../../index";
import URL from "../../config/default.json"

import selectFile from "../../img/icons/fileUpload.svg";
import envelope from "../../img/icons/Envelope.svg";


const BaseTemplate: FC = () => {
    const {store} = useContext(Context);

    let currDate;
    let dateStartShowCfaDB = store.releaseCfaInfo ? store.releaseCfaInfo.dateStartShowCfa : '';
    if (dateStartShowCfaDB && dateStartShowCfaDB !== '') {
        currDate = new Date(dateStartShowCfaDB.split('.')[1]+'/'+dateStartShowCfaDB.split('.')[0]+'/'+dateStartShowCfaDB.split('.')[2]);
    }
    const [startDate, setStartDate] = useState<any>(currDate);
    const setMinStartDate = (date:any) => {
        setStartDate(date);
        setMinDateEndShowCfa(date);
        setMinDateCompleateCfa(date);
        setMinDateEmissionCfa(date);
        if (date > startDate2) {
            setStartDate2(date);
        }
        if (date > startDate3) {
            setStartDate3(date);
        }
        if (date > startDate4) {
            setStartDate4(date);
        }
    }

    let currDate2;
    let dateEndShowCfaDB = store.releaseCfaInfo ? store.releaseCfaInfo.dateEndShowCfa : '';
    if (dateStartShowCfaDB && dateStartShowCfaDB !== '') {
        currDate2 = new Date(dateEndShowCfaDB.split('.')[1]+'/'+dateEndShowCfaDB.split('.')[0]+'/'+dateEndShowCfaDB.split('.')[2]);
    }
    const [startDate2, setStartDate2] = useState<any>(currDate2);
    const setMinStartDate2 = (date:any) => {
        setStartDate2(date);
        setMinDateEmissionCfa(date);
        if (date > startDate3) {
            setStartDate3(date);
            setMinDateCompleateCfa(date)
        }
        if (date > startDate4) {
            setStartDate4(date);
        }
    }

    let currDate3;
    let dateEmissionCfaDB = store.releaseCfaInfo ? store.releaseCfaInfo.dateEmissionCfa : '';
    if (dateStartShowCfaDB && dateStartShowCfaDB !== '') {
        currDate3 = new Date(dateEmissionCfaDB.split('.')[1]+'/'+dateEmissionCfaDB.split('.')[0]+'/'+dateEmissionCfaDB.split('.')[2]);
    }
    const [startDate3, setStartDate3] = useState<any>(currDate3);
    const setMinStartDate3 = (date:any) => {
        setStartDate3(date);
        setMinDateCompleateCfa(date);
        if (date > startDate4) {
            setStartDate4(date);
        }
    }

    let currDate4;
    let dateDateCompleateCfa = store.releaseCfaInfo ? store.releaseCfaInfo.dateEmissionCfa : '';
    if (dateDateCompleateCfa && dateDateCompleateCfa !== '') {
        currDate4 = new Date(dateDateCompleateCfa.split('.')[1]+'/'+dateDateCompleateCfa.split('.')[0]+'/'+dateDateCompleateCfa.split('.')[2]);
    }
    const [startDate4, setStartDate4] = useState<any>(currDate4);
    const setMinStartDate4 = (date:any) => {
        setStartDate4(date);
    }

    const [minDateEndShowCfa, setMinDateEndShowCfa] = useState<any>(currDate2 || new Date());
    const [minDateEmissionCfa, setMinDateEmissionCfa] = useState<any>(currDate3 || new Date());
    const [minDateCompleateCfa, setMinDateCompleateCfa] = useState<any>(currDate4 || new Date());


    let stepActiveBtn:boolean = true;
    let stepActiveItem:string = 'step1';

    const [validated, set_Validated] = useState<boolean>(false);
    const [key, setKey] = useState<any>(stepActiveItem);
    const [disableBtn, setActive] = useState<boolean>(stepActiveBtn);
    const [textAreaCount, setCounterData] = useState<number>(store.releaseCfaInfo && store.releaseCfaInfo.moreInfo ? store.releaseCfaInfo.moreInfo.length : 0);
    const fileLimit = URL.FILE_LIMIT;
    const maxLength = URL.TEXTAREA_LIMIT;

    // variables for uploadReleaseCfa input
    const uploadReleaseCFADBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadReleaseCfaPath ? store.releaseCfaInfo.uploadReleaseCfaPath : '';
    const fileNameReleaseCFADB = uploadReleaseCFADBelemnt !== '' ? uploadReleaseCFADBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseReleaseCFA = useRef<HTMLInputElement>(null);
    const [errorFileSizeReleaseCFA, setErrorReleaseCFA] = useState<boolean>(false);
    const [filenameReleaseCFA, setFilenameReleaseCFA] = useState<string>(fileNameReleaseCFADB ? fileNameReleaseCFADB : 'Решение о выпуске ЦФА');
    const uploadHandleChnageReleaseCFA = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorReleaseCFA(true);
            
            if (filePickerLicenseReleaseCFA.current) {
                filePickerLicenseReleaseCFA.current.value = "";
            }
        } else {
            setFilenameReleaseCFA(e.target.files[0].name);
            setErrorReleaseCFA(false);
        }
    }

    const handlePickReleaseCFA = () => {
        if (filePickerLicenseReleaseCFA.current) {
            filePickerLicenseReleaseCFA.current.click();
        }
    }

    // variables for uploadUNEP input
    const uploadUNEPDBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadUNEPPath ? store.releaseCfaInfo.uploadUNEPPath : '';
    const fileNameUNEPDB = uploadUNEPDBelemnt !== '' ? uploadUNEPDBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseUNEP = useRef<HTMLInputElement>(null);
    const [errorFileSizeUNEP, setErrorUNEP] = useState<boolean>(false);
    const [filenameUNEP, setFilenameUNEP] = useState<string>(fileNameUNEPDB ? fileNameUNEPDB : 'УНЭП к решению о выпуске');
    const uploadHandleChnageUNEP = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorUNEP(true);
            
            if (filePickerLicenseUNEP.current) {
                filePickerLicenseUNEP.current.value = "";
            }
        } else {
            setFilenameUNEP(e.target.files[0].name);
            setErrorUNEP(false);
        }
    }

    const handlePickUNEP = () => {
        if (filePickerLicenseUNEP.current) {
            filePickerLicenseUNEP.current.click();
        }
    }

    // variables for uploadRisks input
    const uploadRisksDBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadRisksPath ? store.releaseCfaInfo.uploadRisksPath : '';
    const fileNameRisksDB = uploadRisksDBelemnt !== '' ? uploadRisksDBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseRisks = useRef<HTMLInputElement>(null);
    const [errorFileSizeRisks, setErrorRisks] = useState<boolean>(false);
    const [filenameRisks, setFilenameRisks] = useState<string>(fileNameRisksDB ? fileNameRisksDB : 'Уведомление о рисках');
    const uploadHandleChnageRisks = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorRisks(true);
            
            if (filePickerLicenseRisks.current) {
                filePickerLicenseRisks.current.value = "";
            }
        } else {
            setFilenameRisks(e.target.files[0].name);
            setErrorRisks(false);
        }
    }

    const handlePickRisks = () => {
        if (filePickerLicenseRisks.current) {
            filePickerLicenseRisks.current.click();
        }
    }

    // variables for uploadSignature input
    const uploadSignatureDBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadSignaturePath ? store.releaseCfaInfo.uploadSignaturePath : '';
    const fileNameSignatureDB = uploadSignatureDBelemnt !== '' ? uploadSignatureDBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseSignature = useRef<HTMLInputElement>(null);
    const [errorFileSizeSignature, setErrorSignature] = useState<boolean>(false);
    const [filenameSignature, setFilenameSignature] = useState<string>(fileNameSignatureDB ? fileNameSignatureDB : 'Файл подписи к уведомлению о рисках');
    const uploadHandleChnageSignature = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorSignature(true);
            
            if (filePickerLicenseSignature.current) {
                filePickerLicenseSignature.current.value = "";
            }
        } else {
            setFilenameSignature(e.target.files[0].name);
            setErrorSignature(false);
        }
    }

    const handlePickSignature = () => {
        if (filePickerLicenseSignature.current) {
            filePickerLicenseSignature.current.click();
        }
    }

    const [requiredReleaseCFA, setrequiredReleaseCFA] = useState<boolean>(false);

    const ownerTypeDB = store.releaseCfaInfo.ownerType;

    const [form_Data, set_Form_Data] = useState({
        emitName: store.company.organizationalLegalFor + ' ' + store.company.shortCompanyName,
        releaseName: store.releaseCfaInfo.releaseName,
        ticker: store.releaseCfaInfo.ticker,
        ownerType: store.releaseCfaInfo.ownerType,
        countCfa: store.releaseCfaInfo.countCfa,
        priceCfa: store.releaseCfaInfo.priceCfa,
        dateStartShowCfa: store.releaseCfaInfo.dateStartShowCfa,
        dateEndShowCfa: store.releaseCfaInfo.dateEndShowCfa,
        dateEmissionCfa: store.releaseCfaInfo.dateEmissionCfa,
        priceCompleateCfa: store.releaseCfaInfo.priceCompleateCfa,
        dateCompleateCfa: store.releaseCfaInfo.dateCompleateCfa,
        moreInfo: store.releaseCfaInfo.moreInfo
    });

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });

        if (name === 'moreInfo') {
            setCounterData(e.target.value.length);
        }
    };

    const NextStep = (e: any) => {
        const form = e.currentTarget.closest('form');
        e.preventDefault();
        if (form.checkValidity() === true) {
            const emitName = store.company.organizationalLegalFor + ' ' + store.company.shortCompanyName;
            const releaseName = form.elements.releaseName.value;
            const ticker = form.elements.ticker.value;
            const ownerType = form.elements.ownerType.value;
            const countCfa = form.elements.countCfa.value;
            const priceCfa = form.elements.priceCfa.value;
            const dateStartShowCfa = form.elements.dateStartShowCfa.value;
            const dateEndShowCfa = form.elements.dateEndShowCfa.value;
            const dateEmissionCfa = form.elements.dateEmissionCfa.value;
            const priceCompleateCfa = form.elements.priceCompleateCfa.value;
            const dateCompleateCfa = form.elements.dateCompleateCfa.value;
            const dateAdded = form.elements.dateAdded.value;
            const isFormCopleated = 'false';
            const uploadReleaseCfa = form.elements.uploadReleaseCfa.files[0];
            const removeuploadReleaseCfa = uploadReleaseCFADBelemnt;
            const uploadUNEP = form.elements.uploadUNEP.files[0];
            const removeuploadUNEP = uploadUNEPDBelemnt;
            const uploadRisks = form.elements.uploadRisks.files[0];
            const removeuploadRisks = uploadRisksDBelemnt;
            const uploadSignature = form.elements.uploadSignature.files[0];
            const removeuploadSignature = uploadSignatureDBelemnt;
            const moreInfo = form.elements.moreInfo.value;


            store.saveReleaseCFA(store.user.email, store.user.id, emitName, releaseName, ticker, ownerType, countCfa, priceCfa, dateStartShowCfa, dateEndShowCfa, dateEmissionCfa, priceCompleateCfa, dateCompleateCfa, dateAdded, uploadReleaseCfa, isFormCopleated, removeuploadReleaseCfa, uploadUNEP, removeuploadUNEP, uploadRisks, removeuploadRisks, uploadSignature, removeuploadSignature, moreInfo);
            set_Validated(false);

            setKey(e.target.id);
            setActive(false);
            window.scrollTo(0, 0);
            if (fileNameReleaseCFADB !== '') {
                setrequiredReleaseCFA(false);
            } else {
                setrequiredReleaseCFA(true);
            }
            
        } else {
            set_Validated(true);
        }
    }

    const saveReleaseFfa = (e: any, saveFormay:boolean) => {
        const form = e.currentTarget.closest('form');
        e.preventDefault();
        if (form.checkValidity() === true) {
            const emitName = store.company.organizationalLegalFor + ' ' + store.company.shortCompanyName;
            const releaseName = form.elements.releaseName.value;
            const ticker = form.elements.ticker.value;
            const ownerType = form.elements.ownerType.value;
            const countCfa = form.elements.countCfa.value;
            const priceCfa = form.elements.priceCfa.value;
            const dateStartShowCfa = form.elements.dateStartShowCfa.value;
            const dateEndShowCfa = form.elements.dateEndShowCfa.value;
            const dateEmissionCfa = form.elements.dateEmissionCfa.value;
            const priceCompleateCfa = form.elements.priceCompleateCfa.value;
            const dateCompleateCfa = form.elements.dateCompleateCfa.value;
            const dateAdded = form.elements.dateAdded.value;
            const uploadReleaseCfa = form.elements.uploadReleaseCfa.files[0];
            const removeuploadReleaseCfa = uploadReleaseCFADBelemnt;
            const uploadUNEP = form.elements.uploadUNEP.files[0];
            const removeuploadUNEP = uploadUNEPDBelemnt;
            const uploadRisks = form.elements.uploadRisks.files[0];
            const removeuploadRisks = uploadRisksDBelemnt;
            const uploadSignature = form.elements.uploadSignature.files[0];
            const removeuploadSignature = uploadSignatureDBelemnt;
            const moreInfo = form.elements.moreInfo.value;

            let isFormCopleated = 'false';
            if (saveFormay) {
                isFormCopleated = 'true';
            }

            store.saveReleaseCFA(store.user.email, store.user.id, emitName, releaseName, ticker, ownerType, countCfa, priceCfa, dateStartShowCfa, dateEndShowCfa, dateEmissionCfa, priceCompleateCfa, dateCompleateCfa, dateAdded, uploadReleaseCfa, isFormCopleated, removeuploadReleaseCfa, uploadUNEP, removeuploadUNEP, uploadRisks, removeuploadRisks, uploadSignature, removeuploadSignature, moreInfo);
            set_Validated(false);
            if (saveFormay) {
                set_Form_Data({
                    emitName: store.company.organizationalLegalFor + ' ' + store.company.shortCompanyName,
                    releaseName: '',
                    ticker: '',
                    ownerType: '',
                    countCfa: '',
                    priceCfa: '',
                    dateStartShowCfa: '',
                    dateEndShowCfa: '',
                    dateEmissionCfa: '',
                    priceCompleateCfa: '',
                    dateCompleateCfa: '',
                    moreInfo: ''
                });
                setFilenameReleaseCFA('Решение о выпуске ЦФА');
                setFilenameUNEP('УНЭП к решению о выпуске');
                setFilenameRisks('Уведомление о рисках');
                setFilenameSignature('Файл подписи к уведомлению о рисках');

                if (filePickerLicenseReleaseCFA.current) {
                    filePickerLicenseReleaseCFA.current.value = "";
                }
                if (filePickerLicenseSignature.current) {
                    filePickerLicenseSignature.current.value = "";
                }
                if (filePickerLicenseRisks.current) {
                    filePickerLicenseRisks.current.value = "";
                }
                if (filePickerLicenseUNEP.current) {
                    filePickerLicenseUNEP.current.value = "";
                }
                setShowConfirmation(true);
            } else {
                setShow(true);
            }
        } else {
            set_Validated(true);
            setShowError(true);
        }
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleCloseConfirmation = () => setShowConfirmation(false);
    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);

    return ( <>
        {store.isAuth && store.user.isActivated ? (<>
            <div className='organization-links'>
                <Container>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Link className="btn btn-secondary my-3" to="/release-cfa">Вернуться к списку ЦФА</Link>
                    </div>
                </Container>
            </div>
            <Container className="base-template">
                <Row>
                    <Col lg={6} className="pe-0">
                        <Form noValidate validated={validated} className="base-template-form">
                            <Tabs 
                                defaultActiveKey={stepActiveItem}
                                variant="pills"
                                id="pills-tab-example"
                                className="mb-3"
                                justify
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                >
                                <Tab eventKey="step1" title="Общая информация о ЦФА">
                                    <Form.Group as={Col} className="mb-3" controlId="validationremitName">
                                        <FormLabel className='required'>Наименование эмитента</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="emitName"
                                            value={form_Data.emitName}
                                            onChange={chngFn}
                                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-']+$"
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Наименование эмитента'
                                            disabled
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationrreleaseName">
                                        <FormLabel className='required'>Наименование выпуска</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="releaseName"
                                            value={form_Data.releaseName}
                                            onChange={chngFn}
                                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9- ']+$"
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Укажите название выпуска ЦФА'
                                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9- ]+$/.test(form_Data.releaseName)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationrreticker">
                                        <FormLabel className='required'>Тикер</FormLabel>
                                        <Form.Control
                                            type="text"
                                            minLength={3}
                                            maxLength={6}
                                            name="ticker"
                                            value={form_Data.ticker}
                                            onChange={chngFn}
                                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-']+$"
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Укажите короткое название из 3-6 символов'
                                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-]+$/.test(form_Data.ticker)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Длина поля должна быть от 3 до 6 символов
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationownerType">
                                        <FormLabel className='required'>Вид прав</FormLabel>
                                        <Form.Select
                                            name="ownerType"
                                            className='w-100 legal-form-1'
                                            required
                                        >
                                            <option value="">Выберите из списка</option>
                                            {ownerTypeDB === 'Денежное требования' ? (<option selected value="Денежное требования"> Денежное требования</option>) : (<option value="Денежное требования"> Денежное требования</option>)}
                                            {ownerTypeDB === 'Гибридные цифровые права' ? (<option selected value="Гибридные цифровые права"> Гибридные цифровые права</option>) : (<option value="Гибридные цифровые права"> Гибридные цифровые права</option>)}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                        
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationrrecountCfa">
                                        <FormLabel className='required'>Количество</FormLabel>
                                        <Form.Control
                                            type="number"
                                            name="countCfa"
                                            value={form_Data.countCfa}
                                            min={1}
                                            onChange={chngFn}
                                            pattern="^[0-9-]+$"
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Укажите количество выпускаемых ЦФА в единицах'
                                            isInvalid={ validated && !/^[0-9-]+$/.test(form_Data.countCfa)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validatiopriceCfa">
                                        <FormLabel className='required'>Цена ЦФА</FormLabel>
                                        <Form.Control
                                            type="number"
                                            name="priceCfa"
                                            onChange={chngFn}
                                            value={form_Data.priceCfa}
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Цена ЦФА'
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationrrecpriceCompleateCfa">
                                        <FormLabel className='required'>Цена погашения ЦФА</FormLabel>
                                        <Form.Control
                                            type="number"
                                            name="priceCompleateCfa"
                                            value={form_Data.priceCompleateCfa}
                                            onChange={chngFn}
                                            pattern="^[0-9-]+$"
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Укажите цену погашения единицы ЦФА'
                                            isInvalid={ validated && !/^[0-9-]+$/.test(form_Data.priceCompleateCfa)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validatiodatedateStartShowCfa">
                                        <FormLabel className='required'>Дата начала размещения ЦФА</FormLabel>
                                        <DatePicker placeholderText="дд.мм.гггг" wrapperClassName="w-100" className="w-100 legal-form-1 form-control" required name="dateStartShowCfa" minDate={new Date()} dateFormat="dd.MM.yyyy" locale={ru} selected={startDate} onChange={setMinStartDate} />
                                    
                                        <Form.Control.Feedback id="aaaa" type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validatiodatedateEndShowCfa">
                                        <FormLabel className='required'>Дата окончания размещения ЦФА</FormLabel>
                                        <DatePicker placeholderText="дд.мм.гггг" wrapperClassName="w-100" className="w-100 legal-form-1 form-control" required name="dateEndShowCfa" minDate={minDateEndShowCfa} dateFormat="dd.MM.yyyy" locale={ru} selected={startDate2} onChange={setMinStartDate2} />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validatiodatedateEmissionCfa">
                                        <FormLabel className='required'>Дата выпуска ЦФА</FormLabel>
                                        <DatePicker placeholderText="дд.мм.гггг" wrapperClassName="w-100" className="w-100 legal-form-1 form-control" required name="dateEmissionCfa" minDate={minDateEmissionCfa} dateFormat="dd.MM.yyyy" locale={ru} selected={startDate3} onChange={setMinStartDate3} />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validatiodatedateCompleateCfa">
                                        <FormLabel className='required'>Дата погашения ЦФА</FormLabel>
                                        <DatePicker placeholderText="дд.мм.гггг" wrapperClassName="w-100" className="w-100 legal-form-1 form-control" required name="dateCompleateCfa" minDate={minDateCompleateCfa} dateFormat="dd.MM.yyyy" locale={ru} selected={startDate4} onChange={setMinStartDate4} />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Control
                                        type="text"
                                        name="dateAdded"
                                        value={new Date().toISOString()}
                                        className='hidden'
                                    />
                                    <Button className='mt-3' variant="secondary" id="step2" onClick={NextStep}>Сохранить и продолжить</Button>
                                </Tab>
                                <Tab eventKey="step2" title="Загрузка документации" disabled={disableBtn}>
                                    <p>Для продолжения процесса выпуска ЦФА загрузите необходимые документы:</p>
                                    <Form.Group as={Col} className="mb-3" controlId="validationLicenseReleaseCFA">
                                        <Form.Control 
                                            type="file"
                                            className="hidden"
                                            onChange={uploadHandleChnageReleaseCFA}
                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                            required={requiredReleaseCFA}
                                            name="uploadReleaseCfa"
                                            ref={filePickerLicenseReleaseCFA}
                                        />
                                        
                                        <div className="filePicker d-flex align-items-center">
                                            <div className="fileIcon col-2" onClick={handlePickReleaseCFA}><img src={selectFile} alt="Select file"/></div>
                                            <div className="fileLable col-10">
                                                {filenameReleaseCFA}
                                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                            </div>
                                            {errorFileSizeReleaseCFA  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Файл обязателен к загрузке
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationLicenseUNEP">
                                        <Form.Control 
                                            type="file"
                                            className="hidden"
                                            onChange={uploadHandleChnageUNEP}
                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                            required={requiredReleaseCFA}
                                            name="uploadUNEP"
                                            ref={filePickerLicenseUNEP}
                                        />
                                        
                                        <div className="filePicker d-flex align-items-center">
                                            <div className="fileIcon col-2" onClick={handlePickUNEP}><img src={selectFile} alt="Select file"/></div>
                                            <div className="fileLable col-10">
                                                {filenameUNEP}
                                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                            </div>
                                            {errorFileSizeUNEP  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Файл обязателен к загрузке
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationLicenseRisks">
                                        <Form.Control 
                                            type="file"
                                            className="hidden"
                                            onChange={uploadHandleChnageRisks}
                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                            required={requiredReleaseCFA}
                                            name="uploadRisks"
                                            ref={filePickerLicenseRisks}
                                        />
                                        
                                        <div className="filePicker d-flex align-items-center">
                                            <div className="fileIcon col-2" onClick={handlePickRisks}><img src={selectFile} alt="Select file"/></div>
                                            <div className="fileLable col-10">
                                                {filenameRisks}
                                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                            </div>
                                            {errorFileSizeRisks  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Файл обязателен к загрузке
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationLicenseSignature">
                                        <Form.Control 
                                            type="file"
                                            className="hidden"
                                            onChange={uploadHandleChnageSignature}
                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                            required={requiredReleaseCFA}
                                            name="uploadSignature"
                                            ref={filePickerLicenseSignature}
                                        />
                                        
                                        <div className="filePicker d-flex align-items-center">
                                            <div className="fileIcon col-2" onClick={handlePickSignature}><img src={selectFile} alt="Select file"/></div>
                                            <div className="fileLable col-10">
                                                {filenameSignature}
                                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                            </div>
                                            {errorFileSizeSignature  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Файл обязателен к загрузке
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3 position-relative" controlId="validationReprRegRegion">
                                        <FormLabel>Дополнительные сведения о выпуске</FormLabel>
                                        <Form.Control
                                            as="textarea"
                                            name="moreInfo"
                                            rows={8}
                                            value={form_Data.moreInfo}
                                            onChange={chngFn}
                                            className='w-100 legal-form-1'
                                            placeholder='Укажитe дополнительную информацию о выпуске ЦФА при необходимости'
                                            maxLength={maxLength}
                                        />
                                        <div className="text-counter">{textAreaCount} / {maxLength}</div>
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button className='mt-3 me-3' variant="secondary" onClick={e => saveReleaseFfa(e, false)}>Сохранить</Button>
                                    <Button className='mt-3' variant="primary" onClick={e => saveReleaseFfa(e, true)}>Сохранить и отправить на согласование</Button>
                                </Tab>
                            </Tabs>
                        </Form>
                        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                            <h4>Даные сохранены</h4>
                            <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                            </Modal.Body>
                            <Modal.Footer>
                            <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                            <div className='btn-outline-wrap-green mt-3 mb-4'>
                            <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showConfirmation} onHide={handleCloseConfirmation} backdrop="static" keyboard={false} >
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                            <p className="congrats-small">Поздравляем!</p>
                            <h4 className="congrats-header">Документация ЦФА успешно загружена и отправлена на проверку</h4>
                            <p className="congrats-text">Статус текущей заявки на выпуск Вы можете отслеживать на вкладке «Выпуск ЦФА»</p>
                            </Modal.Body>
                            <Modal.Footer className="d-block text-center">
                                <Link to="/release-cfa" className='btn btn-primary my-3'>Перейти к «Выпуску ЦФА»</Link>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showError} onHide={handleCloseError} backdrop="static" keyboard={false} >
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                            <h4 className="danger">Ошибка валидации данных</h4>
                            <p className="danger">Пожалуйста проверьте все валидность всех полей в форме, спасибо</p>

                            <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleCloseError}>Закрыть</button>
                            </Modal.Body>
                            <Modal.Footer>
                            <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                            <div className='btn-outline-wrap-green mt-3 mb-4'>
                            <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                    <Col lg={6} className="ps-0">
                        <div className="dark-bg"></div>
                    </Col>
                </Row>
            </Container>
    </>) : (<></>)}
    </>)
}
 
export default BaseTemplate;