import {FC, useContext} from 'react';
import {observer} from "mobx-react-lite";
import {useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from 'react-bootstrap';

import {Context} from "../../index";
import CfaAccountSubMenu from '../account/parts/CfaAccountSubMenu';

import emitImage from "../../img/icons/emiter-default.svg"
import download from "../../img/icons/download.svg";
import checkingImage from "../../img/icons/checking.svg";
import doneImage from "../../img/icons/done.svg";
import cancelImage from "../../img/icons/cancel.svg";
import tooltipimg from "../../img/icons/Info-circle-white.svg";

const ReleaseCfaDetail:FC = () => {
    const {store} = useContext(Context);
    const params = useParams();
    let emitID:any = params.emitID
    let cfaID:any = params.cfaID;
    const cfaItem = store.cfaItem;
    if (cfaItem && cfaItem.ticker === undefined) {
        store.getDetailCFA(emitID, cfaID);
    }
    
    let text = 'На проверке';
    let image = checkingImage;
    let classes = 'cfa-status confirmation';

    let status = cfaItem.status;
    if (status === 'declined') {
        text = 'Отменен Платформой';
        image = cancelImage;
        classes = 'cfa-status cancel';
    } else if (status === 'approval' || status === 'OIS_approval') {
        text = 'На согласовании';
        image = checkingImage;
        classes = 'cfa-status confirmation';
    } else if (status === 'approved') {
        text = 'Согласован';
        image = doneImage;
        classes = 'cfa-status done';
    } else if (status === 'collecting_applications') {
        text = 'Сбор Заявок';
        image = checkingImage;
        classes = 'cfa-status done';
    } else if (status === 'collecting_compleated') {
        text = 'Закрыт сбор заявок';
        image = doneImage;
        classes = 'cfa-status done';
    } else if (status === 'active') {
        text = 'Активен';
        image = doneImage;
        classes = 'cfa-status done';
    } else if (status === 'redeem_started') {
        text = 'В процессе погашения';
        image = doneImage;
        classes = 'cfa-status done';
    } else if (status === 'redeemed') {
        text = 'Погашен';
        image = doneImage;
        classes = 'cfa-status done';
    }

    return (<>
            {store.isAuth && store.user.isActivated ? (<>
                <CfaAccountSubMenu class1="d-none" class2="d-none" class3="d-none"/>
                <Container className='mb-5'>
                <div className='dashboard-cfa mt-5'>
                    <Row className="cfa-table-header mt-5 me-0 ms-0">
                        <Col lg={4}>Наименование</Col>
                        <Col lg={2}>Эмитент</Col>
                        <Col className="text-center" lg={1}>Количество</Col>
                        <Col className="text-center" lg={1}>Дата погашения</Col>
                        <Col className="text-center" lg={2}>Стоимость</Col>
                        <Col className="text-center" lg={2}>Статус</Col>
                        
                    </Row>
                    <Row className="align-items-center cfa-table-body mt-4 me-0 ms-0">
                        <Col className="ps-0" lg={4}>
                                <div className='cfa-table-title'>
                                    <div className='cfa-table-sub-title'>Digital-Grain_{cfaItem.ticker}</div>
                                    <div className='cfa-table-name'>{cfaItem.releaseName}</div>
                                </div>
                        </Col>
                        <Col lg={2} className='align-items-center'><img className="me-3" src={emitImage} alt="Эмитент"/><span>{cfaItem.emitName}</span></Col>
                        <Col className="text-center" lg={1}>{cfaItem.countCfa}</Col>
                        <Col className="text-center" lg={1}>{cfaItem.dateEmissionCfa}</Col>
                        <Col className="text-center cmpleate-price-cfa" lg={2}>{cfaItem.countCfaOrigin ? (parseInt(cfaItem.countCfaOrigin) * parseInt(cfaItem.priceCfa)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0} &#8381;</Col>
                        <Col className="text-center" lg={2}>
                            <div className={classes}>
                                <img src={image} alt=""/>
                                <span>{text}</span>
                                <img width="20" src={tooltipimg} alt=""/>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='detail-cfa-info mb-3'>
                    <CardTitle className='mt-5'>История операций</CardTitle>
                    <Button variant="secondary"><img src={download} alt="Скачать"/> Скачать эмиссионную документацию</Button>
                </div>
                <Card className="mb-3">
                    <CardBody>
                        <div className='d-flex justify-content-between cfa-table-detail'>
                            <div className='left d-flex gap-3'>
                                <div className='cfa-table-title gray-light'>
                                    <div className='cfa-table-name'>{ cfaItem.dateAdded ? new Date(cfaItem.dateAdded).toISOString().slice(0, 10).split('-').reverse().join('.') : ''}</div>
                                    <div className='cfa-table-sub-title'>дата выпуска ЦФА</div>
                                </div>
                                <div className='cfa-table-title gray-light'>
                                    <div className='cfa-table-name'>{cfaItem.dateStartShowCfa} <span className='ms-2'>00:00 ч</span></div>
                                    <div className='cfa-table-sub-title'>дата начала размещения ЦФА</div>
                                </div>
                                <div className='cfa-table-title gray-dark'>
                                    <div className='cfa-table-name'>{cfaItem.dateCompleateCfa} <span className='ms-2'>23:59 ч</span></div>
                                    <div className='cfa-table-sub-title'>дата окончания размещения ЦФА</div>
                                </div>
                            </div>
                            <div className='right d-flex gap-3'>
                                <div className='cfa-table-title success'>
                                    <div className='cfa-table-name'>{cfaItem.countCfaOrigin}</div>
                                    <div className='cfa-table-sub-title'>объем выпуска ЦФА</div>
                                </div>
                                <div className='cfa-table-title success'>
                                    <div className='cfa-table-name'>{cfaItem.priceCfa} &#8381;</div>
                                    <div className='cfa-table-sub-title'>цена ЦФА при эмиссии</div>
                                </div>
                                <div className='cfa-table-title success'>
                                    <div className='cfa-table-name'>{cfaItem.ownerType}</div>
                                    <div className='cfa-table-sub-title'>вид прав по ЦФА</div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                </Container>
            </>) : (<></>)}
        </>
    );
}
 
export default observer(ReleaseCfaDetail);