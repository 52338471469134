import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Context } from "../..";
import MyCfaInfoService from "../../services/MyCfaInfoService";

export function useAllMyCFA() {
    const {store} = useContext(Context);
    const getMyCfaList = async () =>{
        const response = await MyCfaInfoService.fetchAllMyCFA(store.user.email);
        return response;
    }
    const {data, isSuccess, isError} = useQuery({
        queryKey: ['myCfaList'],
        queryFn: getMyCfaList,
        select: data => data.data,
        enabled: store.isAuth && store.user.isActivated
    });

    useEffect(()=>{
        if (isSuccess) {
            console.log('Data fetch success')
        }
    }, [isSuccess, data])
    useEffect(()=>{
        if (isError) {
            console.log('Data fetch with error')
        }
    }, [isError, data])
    return {data, isSuccess, isError}
}