
function sortByDate(cfaItems) {
    cfaItems.sort(function(a,b){
        return new Date(b.dateAdded) - new Date(a.dateAdded);
    });
    return cfaItems;
}

module.exports = function myCfaList(cfa) {
    let activeList = [];
    const cfalength = cfa.length;
    for (let i = 0; i < cfalength; i++) {
        if (cfa[i].status === 'active' || cfa[i].status === 'redeem_started') {
            activeList.push(cfa[i])
        }
    }
    activeList = sortByDate(activeList);
    return activeList;
}
