import React, {createContext} from 'react';
import { createRoot } from 'react-dom/client';
import Store from "./store/store";
import { RouterProvider } from 'react-router-dom';
import {routes} from "./routes/Routes";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
interface State {
    store: Store,
}

export const store = new Store();

export const Context = createContext<State>({
    store,
})

const queryClient = new QueryClient()

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <Context.Provider value={{store}}>
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={routes}/>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </Context.Provider>
);
