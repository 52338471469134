
function sortByDate(cfaItems) {
    cfaItems.sort(function(a,b){
        return new Date(b.dateAdded) - new Date(a.dateAdded);
    });
    return cfaItems;
}

module.exports = function transactionList(cfa) {
    let activeList = [];
    let dateSortedList = {};
    const cfalength = cfa.length;
    for (let i = 0; i < cfalength; i++) {
        activeList.push(cfa[i])
    }
    activeList = sortByDate(activeList);
    let currDate = new Date();
    let previousDate = new Date();
    let dateArr = []
    for (let i = 0; i < cfalength; i++) {
        if (activeList[i].dateAdded && activeList[i].statusOfTransaction === 'buy') {
            currDate = activeList[i].dateAdded.slice(0, 10).split('-').reverse().join('.');
            if (previousDate === currDate || i === 0) {
                dateArr.push(activeList[i])
            }
            if ((previousDate !== currDate && i !== 0) || i === cfalength - 1) {
                dateSortedList[previousDate] = dateArr;
                dateArr = [];
                dateArr.push(activeList[i])
            }
            previousDate = currDate;
        }
    }
    
    return dateSortedList;
}
