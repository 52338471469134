function sortByDate(cfaItems) {
    cfaItems.sort(function(a,b){
        return new Date(b.dateAdded) - new Date(a.dateAdded);
    });
    return cfaItems;
}

module.exports = function activeCfaList(cfa) {
    let activeList = [];
    const cfalength = cfa.length;
    const currDate = new Date();
    for (let i = 0; i < cfalength; i++) {
        if (cfa[i].releaseCfa.length > 0) {
            const cfaItem = cfa[i].releaseCfa;
            for (let y = 0; y < cfaItem.length; y++) {
                let startDate = cfaItem[y].dateStartShowCfa;
                if (startDate && startDate !== '') {
                    startDate = new Date(startDate.split('.')[1]+'/'+startDate.split('.')[0]+'/'+startDate.split('.')[2]);
                }
                let endDate = cfaItem[y].dateEndShowCfa;
                if (endDate && endDate !== '') {
                    endDate = new Date(endDate.split('.')[1]+'/'+endDate.split('.')[0]+'/'+endDate.split('.')[2]);
                    // 24h means till the end of the day. 23:59 of the current date
                    endDate.setHours(endDate.getHours() + 24);
                }
                if (cfaItem[y].status === 'collecting_applications' && startDate <= currDate && endDate >= currDate && cfaItem[y].countCfa > 0) {
                    cfaItem[y].emitID = cfa[i]._id;
                    activeList.push(cfaItem[y]);
                }
            }
        }
    }
    activeList = sortByDate(activeList);
    return activeList;
}
