import {makeAutoObservable} from "mobx";
import axios from 'axios';

import {API_URL} from "../http";

import AuthService from "../services/AuthService";
import {AuthResponse} from '../models/response/AuthResponce';

import CompanyService from "../services/CompanyService";
import LicenseService from "../services/LicenseService";
import OwnerService from "../services/OwnerService";
import BankService from "../services/BankService";
import GenerelInfoService from "../services/GenerelInfoService";
import ConfirmInfoService from "../services/ConfirmInfoService";
import InfoMainService from "../services/InfoMainService";
import HarvestInfoService from "../services/HarvestInfoService";
import LandInfoService from "../services/LandInfoService";
import TechniqueInfoService from "../services/TechniqueInfoService";
import WarehouseInfoService from "../services/WarehouseInfoService";
import StocksInfoService from "../services/StocksInfoService";
import TransactionInfoService from "../services/TransactionInfoService";
import MyCfaInfoService from "../services/MyCfaInfoService";
import ReleaseCfaService from "../services/ReleaseCfaService";

import {IUser} from "../models/IUser";
import { ICompany } from "../models/ICompany";
import { ILicense } from "../models/ILicense";
import { IOwner } from "../models/IOwner";
import { IBank } from "../models/IBank";
import { IGenerelInfo } from "../models/IGenerelInfo";
import { IConfirmInfo } from "../models/IConfirmInfo";
import { IInfoMain } from "../models/IInfoMain";
import { IHarvest } from "../models/IHarvest";
import { ILand } from "../models/ILand";
import { ITechnique } from "../models/ITechnique";
import { IWarehouse } from "../models/IWarehouse";
import { IStocks } from "../models/IStocks";
import { ITransaction } from "../models/ITransaction";
import { IReleaseCfa } from "../models/IReleaseCfa";
import { IMyCfa } from "../models/IMyCfa";



export default class Store {
    user = {} as IUser;
    company = {} as ICompany;
    license = {} as ILicense;
    owner = {} as IOwner;
    bank = {} as IBank;
    generelInfo = {} as IGenerelInfo;
    confirmInfo = {} as IConfirmInfo;
    infoMain = {} as IInfoMain;
    harvestInfo = {} as IHarvest;
    landInfo = {} as ILand;
    techniqueInfo = {} as ITechnique;
    warehouseInfo = {} as IWarehouse;
    stocksInfo = {} as IStocks;
    transactionInfo = {} as ITransaction;
    releaseCfaInfo = {} as IReleaseCfa;
    cfaItem = {} as IReleaseCfa;
    myCfa = {} as IMyCfa;
    
    isAuth = false;
    isLoading = false;
    errorMessage = '';
    restoreMesssage = '';
    restoreError = false;
    updatePasswordMesssage = '';
    updatePasswordError = false;
    loader = false;
    isUserActivated = false;
    cfaList = [];

    constructor() {
        makeAutoObservable(this);
    }

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    setUserActivated(bool: boolean) {
        this.isUserActivated = bool;
    }

    setUser(user: IUser) {
        this.user = user;
    }

    setCompany(company: ICompany) {
        this.company = company;
    }

    setLicense(license: ILicense) {
        this.license = license;
    }

    setOwner(owner: IOwner) {
        this.owner = owner;
    }

    setBank(bank: IBank) {
        this.bank = bank;
    }

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setError(error: string) {
        this.errorMessage = error;
    }

    setGenerelInfo(generelInfo: IGenerelInfo) {
        this.generelInfo = generelInfo;
    }

    setConfirmInfo(confirmInfo: IConfirmInfo) {
        this.confirmInfo = confirmInfo;
    }

    setInfoMain(infoMain: IInfoMain) {
        this.infoMain = infoMain;
    }

    setHarvestInfo(harvestInfo: IHarvest) {
        this.harvestInfo = harvestInfo;
    }

    setLandInfo(landInfo: ILand) {
        this.landInfo = landInfo;
    }

    setTechniqueInfo(techniqueInfo: ITechnique) {
        this.techniqueInfo = techniqueInfo;
    }

    setWarehouseInfo(warehouseInfo: IWarehouse) {
        this.warehouseInfo = warehouseInfo;
    }

    setStocksInfo(stocksInfo: IStocks) {
        this.stocksInfo = stocksInfo;
    }

    setTransactionInfo(transactionInfo: ITransaction) {
        this.transactionInfo = transactionInfo;
    }

    setReleaseCfa(releaseCfaInfo: IReleaseCfa) {
        this.releaseCfaInfo = releaseCfaInfo;
    }

    setRestoreEmail(restoreEmail:any) {
        this.restoreMesssage = restoreEmail.message;
        this.restoreError = restoreEmail.error;
        this.loader = false;
    }

    setUpdatePassword(updateResponse:any) {
        this.updatePasswordMesssage = updateResponse.message;
        this.updatePasswordError = updateResponse.error;
    }

    setListCfa(cfaListArray: any) {
        this.cfaList = cfaListArray
    }

    setCfaItem(CfaItem:any) {
        this.cfaItem = CfaItem;
    }

    setMyCfa(myCfa:any) {
        this.myCfa = myCfa;
    }

    async login(email: string, password: string) {
        try {
            const response = await AuthService.login(email, password);
            if (response.data.user.isActivated) {
                this.setUserActivated(true);
            }
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async loginAuth(email: string, password: string, authCode:string) {
        try {
            const response = await AuthService.loginAuth(email, password, authCode);
            localStorage.setItem('token', response.data.accessToken);
            
            this.setAuth(true);
            this.setUser(response.data.user);
            this.setError('');
            this.checkAuth();
            window.location.href = "/account";
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async registration(email: string, password: string, confirmPassword: string, name: string, surname: string, patronymic: string, status: string, companyName: string) {
        try {
            if (password === confirmPassword) {
                const response = await AuthService.registration(email, password, name, surname, patronymic, status, companyName);
                localStorage.setItem('token', response.data.accessToken);
                this.setAuth(true);
                this.setUser(response.data.user);
            } else {
                this.setError('Пароли не совпадают');
            }
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async updatePassword(password: string, confirmPassword: string, hash: any) {
        try {
            if (password === confirmPassword) {
                const response = await AuthService.updatePassword(password, hash);
                this.setUpdatePassword(response.data);
                if (response.data.error) {
                    this.setError(response.data.message)
                }
            } else {
                this.setError('Пароли не совпадают');
            }
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    

    async saveCompany(email: string, user:string, organizationalLegalFor: string, shortCompanyName: string, companyPhone: string, companyEmail: string, companyWebSite: string, ogrnName: string, innName: string, uploadOgrn: File, uploadInn: File, kppName: string, okpoName: string, okatoName: string, okvedName: string, regDate: string, nameRegAuthority: string, gosRegRegion: string, gosRegCity: string, gosRegStreet: string, gosRegHouse: string, gosRegCorpus: string, gosRegApp: string, gosPostalCode: string, isLegalAddSameAsGos: string, isPostAddSameAsGos:string, legalRegRegion: string, legalRegCity: string, postRegRegion: string, postRegCity: string, legalRegStreet: string, legalRegHouse: string, legalRegCorpus: string, legalRegApp: string, legalPostalCode: string, postRegStreet: string, postRegHouse: string, postRegCorpus: string, postRegApp: string, postPostalCode: string ) {
        try {
            const response = await CompanyService.saveCompany(email, user, organizationalLegalFor, shortCompanyName, companyPhone, companyEmail, companyWebSite, ogrnName, innName, uploadOgrn, uploadInn, kppName, okpoName, okatoName, okvedName, regDate, nameRegAuthority, gosRegRegion, gosRegCity, gosRegStreet, gosRegHouse, gosRegCorpus, gosRegApp, gosPostalCode, isLegalAddSameAsGos, isPostAddSameAsGos, legalRegRegion, legalRegCity, postRegRegion, postRegCity, legalRegStreet, legalRegHouse, legalRegCorpus, legalRegApp, legalPostalCode, postRegStreet, postRegHouse, postRegCorpus, postRegApp, postPostalCode);
            this.setCompany(response.data.company);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async saveLicense(email: string, user:string, typeActivity: string, numberAct:string, dateAct: string, officeAct: string, dateEnd: string, uploadCopyScan: File, isBankruptcy:string, isDoneBankruptcy:string, isLiquidation:string, isFailureObligation:string, isDebt:string, financialInfo:string, businessReputation:string, uploadFinanceDoc: File, uploadFinSituation: File, uploadFinReputation: File) {
        try {
            const response = await LicenseService.saveLicense(email, user, typeActivity, numberAct, dateAct, officeAct, dateEnd, uploadCopyScan, isBankruptcy, isDoneBankruptcy, isLiquidation, isFailureObligation, isDebt, financialInfo, businessReputation, uploadFinanceDoc, uploadFinSituation, uploadFinReputation);
            this.setLicense(response.data.license);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async saveOwner(email: string, user:string, organizationalStructureLegalEntity: string, beneficialOwner: string, partControlOwner: boolean, fullCOntrolOwner: boolean) {
        try {
            const response = await OwnerService.saveOwner(email, user, organizationalStructureLegalEntity, beneficialOwner, partControlOwner, fullCOntrolOwner);
            this.setOwner(response.data.owner);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }
    
    async saveBank(email: string, user:string, currentBankAccount: string, bankName: string, bankBIK: string, corrAccount: string, bankInnCode: string, bankKPP: string, platformRole: string, uploadGosReg: File, uploadFounderDoc: File) {
        try {
            const response = await BankService.saveBank(email, user, currentBankAccount, bankName, bankBIK, corrAccount, bankInnCode, bankKPP, platformRole, uploadGosReg, uploadFounderDoc);
            this.setBank(response.data.bank);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async saveGenerelInfo(email: string, user:string, reprFio: string, repName: string, reprPatronimic: string, dateDateBirthday: string, reprPhone: string, reprEmail: string, reprInn: string, reprSNILS: string, reprBirthdayPlace: string, reprNationality: string, reprPasport: string, reprSerial: string, reprPasportNumber: string, pasportDate: string, reprCodeDepartment: string, reprIssuedBy: string, uploadPasportDoc: File, reprRegRegion: string, reprRegCity: string, reprRegStreet: string, reprRegHouse: string, reprRegCorpus: string, reprRegApp: string, reprPostalCode: string, uploadRegistrationDoc: File, isLiveAddSameAsReg: string, isPostAddSameAsReg: string, addrLiveRegion: string, addrLiveCity: string, addrLiveStreet: string, addrLiveHouse: string, addrLiveCorpus: string, addrLiveApp: string, addrLivePostalCode: string, postLiveRegion: string, postLiveCity: string, postLiveStreet: string, postLiveHouse: string, postLiveCorpus: string, postLiveApp: string, postLivePostalCode: string) {
        try {
            const response = await GenerelInfoService.saveGenerelInfo(email, user, reprFio, repName, reprPatronimic, dateDateBirthday, reprPhone, reprEmail, reprInn, reprSNILS, reprBirthdayPlace, reprNationality, reprPasport, reprSerial, reprPasportNumber, pasportDate, reprCodeDepartment, reprIssuedBy, uploadPasportDoc, reprRegRegion, reprRegCity, reprRegStreet, reprRegHouse, reprRegCorpus, reprRegApp, reprPostalCode, uploadRegistrationDoc, isLiveAddSameAsReg, isPostAddSameAsReg, addrLiveRegion, addrLiveCity, addrLiveStreet, addrLiveHouse, addrLiveCorpus, addrLiveApp, addrLivePostalCode, postLiveRegion, postLiveCity, postLiveStreet, postLiveHouse, postLiveCorpus, postLiveApp, postLivePostalCode);
            this.setGenerelInfo(response.data.generelInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async saveConfirmInfo(email: string, user:string, confirmName: string, confirmNumber:string, dateAccessStart:string, dateAccessEnd:string, uploadConfirmDoc: File) {
        try {
            const response = await ConfirmInfoService.saveConfirmInfo(email, user, confirmName, confirmNumber, dateAccessStart, dateAccessEnd, uploadConfirmDoc);
            this.setConfirmInfo(response.data.confirmInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async saveInfoMain(email: string, user:string, infoDescription: string, infoDistrict:string, uploadInfoMain: File) {
        try {
            const response = await InfoMainService.saveInfoMain(email, user, infoDescription, infoDistrict, uploadInfoMain);
            this.setInfoMain(response.data.infoMain);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }
    
    async saveHarvestInfo(email: string, user:string, plantasName: string, volumen:string, qualityDescription:string, dateAdded: string, uploadHarvest: File) {
        try {
            const response = await HarvestInfoService.saveHarvestInfoService(email, user, plantasName, volumen, qualityDescription, dateAdded, uploadHarvest);
            this.setHarvestInfo(response.data.harvestInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async removeHarvestItem(email: string, itemId: string, uploadHarvestPath: string) {
        try {
            const response = await HarvestInfoService.removeHarvestItem(email, itemId, uploadHarvestPath);
            this.setHarvestInfo(response.data.harvestInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async removeStocksItem(email: string, itemId: string, uploadStocksPath: string) {
        try {
            const response = await StocksInfoService.removeStocksItem(email, itemId, uploadStocksPath);
            this.setStocksInfo(response.data.stocksInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }
    
    async removeLandItem(email: string, itemId: string, uploadLandPath: string, uploadPhotoLandPath: string) {
        try {
            const response = await LandInfoService.removeLandItem(email, itemId, uploadLandPath, uploadPhotoLandPath);
            this.setLandInfo(response.data.landInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async removeTechniqueItem(email: string, itemId: string, uploadTechniquePath: string, uploadPhotoTechniquePath:any) {
        try {
            const response = await TechniqueInfoService.removeTechniqueItem(email, itemId, uploadTechniquePath, uploadPhotoTechniquePath);
            this.setTechniqueInfo(response.data.techniqueInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async removeWarehouseItem(email: string, itemId: string, uploadWarehousePath: string, uploadPhotoWarehousePath:any) {
        try {
            const response = await WarehouseInfoService.removeWarehouseItem(email, itemId, uploadWarehousePath, uploadPhotoWarehousePath);
            this.setWarehouseInfo(response.data.warehouseInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async saveLandInfo(email: string, user:string, cadastralNumber: string, area:string, isOwnership:string, dateAdded: string, uploadLand: File, uploadLandPhoto:File) {
        try {
            const response = await LandInfoService.saveLandInfoService(email, user, cadastralNumber, area, isOwnership, dateAdded, uploadLand, uploadLandPhoto);
            this.setLandInfo(response.data.landInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async saveTechniqueInfo(email: string, user:string, isTypeOfTechnique: string, nameOfTechnique:string, yearOfTechnique:string, stsOfTechnique: string, dateAdded: string, uploadTechnique: File, uploadTechniquePhoto:File) {
        try {
            const response = await TechniqueInfoService.saveTechniqueInfoService(email, user, isTypeOfTechnique, nameOfTechnique, yearOfTechnique, stsOfTechnique, dateAdded, uploadTechnique, uploadTechniquePhoto);
            this.setTechniqueInfo(response.data.techniqueInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async saveWarehouseInfo(email: string, user:string, isTypeOfWarehouse: string, specifyPurpose:string, usefulVolume:string, dateAdded: string, uploadTechnique: File, uploadTechniquePhoto:File) {
        try {
            const response = await WarehouseInfoService.saveWarehouseInfoService(email, user, isTypeOfWarehouse, specifyPurpose, usefulVolume, dateAdded, uploadTechnique, uploadTechniquePhoto);
            this.setWarehouseInfo(response.data.warehouseInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async saveStocksInfo(email: string, user:string, typeOfGrain: string, countOfGrain: string, qualityOfGrain: string, dateAdded: string, uploadStocks: File) {
        try {
            const response = await StocksInfoService.saveStocksInfoService(email, user, typeOfGrain, countOfGrain, qualityOfGrain, dateAdded, uploadStocks);
            this.setStocksInfo(response.data.stocksInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async saveTransactionInfo(email: string, user:string, numberAccountFrom: string, freeAmount:string, sendAmount:string, dateAdded: string, totalAmount: string) {
        try {
            const response = await TransactionInfoService.saveTransactionInfoService(email, user, numberAccountFrom, freeAmount, sendAmount, dateAdded, totalAmount);
            this.setTransactionInfo(response.data.transactionInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async saveReleaseCFA(email: string, user:string, emitName:string, releaseName:string, ticker:string, ownerType:string, countCfa:string, priceCfa:string, dateStartShowCfa:string, dateEndShowCfa:string, dateEmissionCfa:string, priceCompleateCfa:string, dateCompleateCfa:string, dateAdded:string, uploadReleaseCfa:File, isFormCopleated:string, removeuploadReleaseCfa:string, uploadUNEP:File, removeuploadUNEP:string, uploadRisks:File, removeuploadRisks:string, uploadSignature:File, removeuploadSignature:string, moreInfo: string) {
        try {
            const response = await ReleaseCfaService.saveReleaseCfaService(email, user, emitName, releaseName, ticker, ownerType, countCfa, priceCfa, dateStartShowCfa, dateEndShowCfa, dateEmissionCfa, priceCompleateCfa, dateCompleateCfa, dateAdded, uploadReleaseCfa, isFormCopleated, removeuploadReleaseCfa, uploadUNEP, removeuploadUNEP, uploadRisks, removeuploadRisks, uploadSignature, removeuploadSignature, moreInfo);
            this.setReleaseCfa(response.data.releaseCfaInfo);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async buyCfaInfo(email: string, user:string, numberAccountFrom: string, freeAmount:string, sendAmount:string, dateAdded: string, totalAmount: string,  emitID: string, cfaID: string, commisionPrice: string) {
        try {
            let getCfaDetail = await ReleaseCfaService.getDetailCFA(emitID, cfaID);
            if (parseInt(sendAmount) > parseInt(getCfaDetail.data.countCfa)) {
                this.setError('Количество ЦФА было изменено, пожалуйста, перезагрузите страницу, чтобы увидеть максимальное количество ЦФА');
            } else {
                const response = await MyCfaInfoService.saveMyCfaInfoService(email, user, numberAccountFrom, freeAmount, sendAmount, dateAdded, totalAmount, emitID, cfaID, commisionPrice);
                const responseCfa = await ReleaseCfaService.fetchCfa();
                this.setListCfa(responseCfa.data);
                this.setMyCfa(response.data.myCfaInfo)
                this.setError('');
            }
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message || 'Ошибка связи с Базой данных, обратитесь в службу поддержки, спасибо');
        }
    }

    async getAllMyCfa(email: string) {
        try {
            const response = await MyCfaInfoService.fetchAllMyCFA(email);
            this.setMyCfa(response.data.myCfaInfo)
            this.setError('');
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message || 'Ошибка связи с Базой данных, обратитесь в службу поддержки, спасибо');
        }
    }

    async getCfa() {
        try {
            const response = await ReleaseCfaService.fetchCfa();
            this.setListCfa(response.data);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async getDetailCFA(emitID:string, cfaID:string) {
        try {
            const response = await ReleaseCfaService.getDetailCFA(emitID, cfaID);
            if (response.data && response.data.ticker === undefined) {
                this.setError('empty_cfa');
            } else {
                this.setError('');
                this.setCfaItem(response.data);
            }
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }
    async redeemCFA(emitID:string, cfaID:string) {
        try {
            const response = await MyCfaInfoService.redeemCFA(emitID, cfaID);
            // const responseCfa = await ReleaseCfaService.fetchCfa();
            // this.setListCfa(responseCfa.data);
            this.setMyCfa(response.data.myCfaInfo)
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async sendEmail(email: string) {
        try {
            const response = await AuthService.restorePassword(email);
            this.setRestoreEmail(response.data);
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
            this.setError(e.response.data.message);
        }
    }

    async logout() {
        try {
            localStorage.removeItem('token');
            this.setAuth(false);
            this.setUser({} as IUser);
            this.setCompany({} as ICompany);
            this.setLicense({} as ILicense);
            this.setOwner({} as IOwner);
            this.setBank({} as IBank);
            this.setGenerelInfo({} as IGenerelInfo);
            this.setConfirmInfo({} as IConfirmInfo);
            this.setInfoMain({} as IInfoMain);
            this.setHarvestInfo({} as IHarvest);
            this.setLandInfo({} as ILand);
            this.setTechniqueInfo({} as ITechnique);
            this.setTransactionInfo({} as ITransaction);
            this.setReleaseCfa({} as IReleaseCfa);

            this.setError('');
            window.location.href = "/login";
        } catch (e: any) {
            console.log(e.message);
        }
    }

    async checkAuth() {
        this.setLoading(true);
        try {
            const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {withCredentials: true});
            if (response.data && response.data.accessToken) {
                localStorage.setItem('token', response.data.accessToken);
                this.setAuth(true);
                if (response.data.user) {
                    this.setUser(response.data.user);
                }
                if (response.data.company) {
                    this.setCompany(response.data.company);
                }
                if (response.data.license) {
                    this.setLicense(response.data.license);
                }
                if (response.data.owner) {
                    this.setOwner(response.data.owner);
                }
                if (response.data.bank) {
                    this.setBank(response.data.bank);
                }
                if (response.data.generelInfo) {
                    this.setGenerelInfo(response.data.generelInfo);
                }
                if (response.data.confirmInfo) {
                    this.setConfirmInfo(response.data.confirmInfo);
                }
                if (response.data.infoMain) {
                    this.setInfoMain(response.data.infoMain);
                }
                if (response.data.harvestInfo) {
                    this.setHarvestInfo(response.data.harvestInfo);
                }
                if (response.data.landInfo) {
                    this.setLandInfo(response.data.landInfo);
                }
                if (response.data.techniqueInfo) {
                    this.setTechniqueInfo(response.data.techniqueInfo);
                }
                if (response.data.warehouseInfo) {
                    this.setWarehouseInfo(response.data.warehouseInfo);
                }
                if (response.data.stocksInfo) {
                    this.setStocksInfo(response.data.stocksInfo);
                }
                if (response.data.transactionInfo) {
                    this.setTransactionInfo(response.data.transactionInfo);
                }
                if (response.data.releaseCfaInfo) {
                    this.setReleaseCfa(response.data.releaseCfaInfo);
                }
                if (response.data.myCfaInfo) {
                    this.setMyCfa(response.data.myCfaInfo);
                }
            }
        } catch (e) {
            if (
                typeof e === "object" && e && "message" in e && typeof e.message === "string"
              ) {
                // message gets narrowed to string!
                console.log(e.message);
              }
        } finally {
            this.setLoading(false);
        }
    }
}